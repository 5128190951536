import React from 'react';
import PropTypes from 'prop-types';

import Row from './listRowComponent';

export default function ListComponent(props = {}) {
    return (
        <table className="table">
            <thead className="d-none d-md-table-header-group">
                <tr>
                    <th>UPC</th>
                    <th>HiLine ID</th>
                    <th>Description</th>
                    <th>Vendor</th>
                    <th>Order Number</th>
                    <th>Wholesale</th>
                    <th>ASIN</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.items.map((item) => (
                    <Row key={item.id} item={item} onReceiveClick={props.onReceiveClick}/>    
                ))}
            </tbody>
        </table>
    );
}

ListComponent.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onReceiveClick: PropTypes.func.isRequired,
};
