import React from 'react';

import UploadContainer from './uploadContainer';

export default function ReceivingContainer() {

    return (
        <div>
            <h2>Upload Manifest Spreadsheet</h2>
            <p>
                The spreadsheet must have the following column headings, spelled and capitalized exactly as below.
                The order of the columns does not matter.
            </p>
            <p>
                <code>upc</code>,
                <code>description</code>,
                <code>vendor</code>,
                <code>orderNumber</code>,
                <code>orderDate</code>,
                <code>cost</code>,
                {/*<code>storePrice</code>,*/}
                <code>retail</code>,
                <code>hilineId</code>,
                <code>Wholesale</code>,
                <code>ASIN</code>,
                <code>newPrice</code>,
                <code>likeNewPrice</code>
            </p>
            <p>The spreadsheet will either import as a whole or not at all. If any errors occurr the spreadsheet was most likely not imported</p>
            <p><a href="/sample.xlsx">Download a sample spreadsheet</a></p>
            <hr />
            <UploadContainer />
        </div>
    );

}
