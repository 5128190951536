import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';

import LookupComponent from './lookupComponent';

export default class GradingLookupContainer extends React.Component {
    constructor(props = {}) {
        super(props);

        this._loadAndCheckItem = this._loadAndCheckItem.bind(this);
        this.handleHiLineIdLookup = this.handleHiLineIdLookup.bind(this);
        this.handleOrderNumUpcLookup = this.handleOrderNumUpcLookup.bind(this);
    }

    _loadAndCheckItem(params = {}) {
        this.props.onItemLookup(params)
            .then((itemResp) => {
                if (itemResp) {
                    this.props.onLoadedItem(itemResp);
                }
            });
    }

    handleHiLineIdLookup(hilineId) {
        if (!hilineId) {
            Alert.error('Please enter a Hiline ID');
            return;
        }
        this._loadAndCheckItem({
            hilineId,
        });
    }

    handleOrderNumUpcLookup(orderNumber, upc) {
        if (!orderNumber || !upc) {
            Alert.error('Please enter both an order number and UPC');
            return;
        }
        this._loadAndCheckItem({
            orderNumber,
            upc,
        });
    }

    render() {
        return (
            <LookupComponent onOrderUpcSubmit={this.handleOrderNumUpcLookup} onHiLineIdSubmit={this.handleHiLineIdLookup} />
        );
    }
}

GradingLookupContainer.propTypes = {
    onLoadedItem: PropTypes.func.isRequired,
    onItemLookup: PropTypes.func.isRequired,
};
