import Duck from 'extensible-duck';

export default new Duck({
    namespace: 'my-app', store: 'print',
    types: ['SET', 'SAVE'],
    initialState: {
        printerId: null,
        printers: null,
    },
    reducer: (state, action, duck) => {
        switch (action.type) {
        // do reducer stuff
        case duck.types.SET:
            return {
                ...state,
                printerId: Number(action.id),
            };
        case duck.types.SAVE:
            if (Array.isArray(action.printers)) {
                return {
                    ...state,
                    printers: [...action.printers],
                };
            }
            return state;
        default: return state;
        }
    },
    selectors: {
        getPrinterId: (state) => state.printerId != null ? Number(state.printerId) : null,
        getPrinters: (state) => state.printers || [],
    },
    creators: (duck) => ({
        setPrinterId: (printerId) => ({ type: duck.types.SET, id: printerId }),
        savePrinters: (printers) => ({ type: duck.types.SAVE, printers }),
    }),
});
