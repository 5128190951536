import React, { Component } from 'react';
import PropTypes from 'prop-types';

import dateFormat from 'date-fns/format';
import dateAddDays from 'date-fns/add_days';


export default class ReportCriteriaComponent extends Component {
    constructor(props = {}) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleExportClick = this.handleExportClick.bind(this);
    }

    _extractFormData(fd) {
        let output = {};

        for (let itr of fd.entries()) {
            output[itr[0]] = itr[1] || null;
        }

        return output;
    }

    handleFormSubmit(e) {
        e.preventDefault();
        this.props.onFormSubmit(this._extractFormData(new FormData(this.formEl)));
    }

    handleExportClick(e) {
        e.preventDefault();
        this.props.onExportClick(this._extractFormData(new FormData(this.formEl)));
    }
    render() {
        return (
            <form ref={(el) => { this.formEl = el; }} onSubmit={this.handleFormSubmit}>
                <div className="row align-items-end">
                    <div className="col-md-2">
                        <label htmlFor="queryProp">Filter On</label>
                        <select className="form-control" name="queryProp" defaultValue="receivedAt">
                            <option value="uploadedAt">Uploaded Date</option>
                            <option value="receivedAt">Received Date</option>
                            <option value="gradedAt">Graded Date</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="startDate">From</label>
                        <input type="date" className="form-control" name="startDate" defaultValue={dateFormat(dateAddDays(new Date(), -90), 'YYYY-MM-DD')} />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="endDate">To</label>
                        <input type="date" className="form-control" name="endDate" defaultValue={dateFormat(new Date(), 'YYYY-MM-DD')} />
                    </div>
                    <div className="col-md-2 text-right">
                        <button type="submit" className="btn btn-primary">Load Data</button>
                    </div>
                    <div className="col-md-2 text-right">
                        <button className="btn btn-secondary" onClick={this.handleExportClick}>Export</button>
                    </div>
                </div>
            </form>
        );
    }
}

ReportCriteriaComponent.propTypes = {
    onFormSubmit: PropTypes.func.isRequired,
    onExportClick: PropTypes.func.isRequired,
};


