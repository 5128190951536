import 'whatwg-fetch';
import authDucks from '../redux/ducks/auth';
import printDucks from '../redux/ducks/print';
import { saveAs } from 'file-saver';
import Alert from 'react-s-alert';
import { captureException } from '../sentry';
import DEV_URL from '../urls';

var _accessToken = null;
var _printerId = null;

export function setAuth(store) {
    store.subscribe(() => {
        let state = store.getState();

        _accessToken = authDucks.selectors.getAccessToken(state.auth) || null;
    });
}

export function setPrinter(store) {
    store.subscribe(() => {
        let state = store.getState();

        _printerId = printDucks.selectors.getPrinterId(state.print) || null;
    });
}

export function call(url, method = 'GET', data = null) {
    let accessToken = _accessToken;

    let bodyMerge = {};
    let headerMerge = {};
    if (data && data instanceof FormData === false) {
        bodyMerge = {
            body: JSON.stringify(data),
        };
        headerMerge = {
            'Content-Type': 'application/json',
        };
    } else if (data && data instanceof FormData === true) {
        bodyMerge = {
            body: data,
        };
    }
    if (process.env.NODE_ENV === 'development')
        url = new URL(url, DEV_URL);

    let req = new Request(url, Object.assign({
        method: method,
        headers: Object.assign({
            'Authorization': `bearer ${accessToken}`,
            'PrintNode-Printer': _printerId || '',
        }, headerMerge),
    }, bodyMerge));

    let responseObj;
    return fetch(req)
        .then((res) => {
            responseObj = res.clone();
            if (res.status === 503 || res.status === 401) {
                Alert.error('Please log out and back in');
                return {
                    headers: new Map([['Content-Type', 'application/json']]),
                    json: () => Promise.resolve({
                        success: false,
                        message: 'Please log out and log back in. An odd error occured',
                    }),
                };
            }
            if (res.status === 524 || res.status === 504) {
                return {
                    headers: new Map([['Content-Type', 'application/json']]),
                    json: () => Promise.resolve({
                        success: false,
                        message: 'The request timed out. Try with a smaller range of dates or smaller upload file',
                    }),
                };
            }
            if (res.status === 500) {
                return Promise.reject(new Error('Received 500 error from server'));
            }

            return res;
        })
        .then((res) => {
            const contentType = res.headers && res.headers.get('Content-Type');
            if (contentType.includes('json')) {
                return res.json();
            }
            throw new Error(`Unexpected Content-Type "${contentType}"`);
        })
        .catch((error) => {
            if (responseObj) {
                return responseObj.text()
                    .then((responseText) => {
                        captureException(error, {
                            extra: {
                                request: {
                                    url,
                                    method,
                                    data,
                                },
                                response: {
                                    headers: responseObj.headers,
                                    status: responseObj.status,
                                    body: responseText,
                                },
                            },
                        });
                        if (error.toString().includes('Unexpected token < in JSON')) {
                            return {
                                success: false,
                                message: 'An error occured. Please try again',
                            };
                        }
                    });
            }
        });
}

export function callDownload(url, defaultFilename) {
    let accessToken = _accessToken;

    let req = new Request(url, {
        method: 'GET',
        headers: {
            'Authorization': `bearer ${accessToken}`,
        },
    });


    return fetch(req)
        .then((res) => {
            let filenameMatch = res.headers
                .get('Content-Disposition')
                .match(/filename="(.+)"/);

            let filename = filenameMatch[1] || defaultFilename;

            return Promise.all([res.blob(), filename]);
        })
        .then(([blob, fileName]) => saveAs(blob, fileName));
}
