import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import persistState from 'redux-localstorage';
import logger from 'redux-logger';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';

import { createBrowserHistory } from 'history';


import mySagas from './sagas';
import reducers from './reducers';
import { setAuth, setPrinter } from '../lib/api';

// Create a history of your choosing (we're using a browser history in this case)
export const history = createBrowserHistory();

// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history);

// Create Saga Middleware
const sagaMiddleware = createSagaMiddleware();

// Is dev?
const isDev = window.location.hostname === 'localhost';

let middlewareArray = [
    persistState([
        'auth',
    ]),
    applyMiddleware(middleware),
    applyMiddleware(thunk),
    applyMiddleware(sagaMiddleware),

];

// Redux logger
if (isDev) {
    middlewareArray.push(applyMiddleware(logger));
    middlewareArray.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    middlewareArray.push(f => f);
}

export const store = createStore(
    combineReducers({
        ...reducers,
        router: routerReducer,
    }),
    compose(...middlewareArray),
);
setAuth(store);
setPrinter(store);

sagaMiddleware.run(mySagas);
