import React from 'react';
import PropTypes from 'prop-types';

function clearFormValues(formEl) {
    let inputEls = Array.from(formEl.getElementsByTagName('input'));
    inputEls.forEach((el) => {
        el.value = null;
    });
}

export default class FilterComponent extends React.Component {
    constructor(props = {}) {
        super(props);

        this.handleHilineIdSubmit = this.handleHilineIdSubmit.bind(this);
        this.handleOrderUpcSubmit = this.handleOrderUpcSubmit.bind(this);
        this.handleFocusSelectText = this.handleFocusSelectText.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        this.hilineInput.focus();
    }

    handleHilineIdSubmit(e) {
        e.preventDefault();
        this.props.onHiLineIdSubmit(this.hilineInput.value);
        clearFormValues(e.target);
        this.hilineInput.focus();
        return false;
    }

    handleOrderUpcSubmit(e) {
        e.preventDefault();
        
        let inputs = e.currentTarget.getElementsByTagName('input');
        this.props.onOrderUpcSubmit(
            inputs.orderNumber.value,
            inputs.upc.value,
        );

        clearFormValues(e.target);
        this.orderInput.focus();

        return false;
    }

    handleKeyPress(e) {
        if (e.charCode === 10 || e.charCode === 13 || e.keyCode === 10 || e.keyCode === 13) {
            let formInputEls = Array.from(e.currentTarget.getElementsByTagName('input'));

            let currentIdx = formInputEls.indexOf(e.target);

            if (currentIdx > -1) {
                let newTargetEl = formInputEls[currentIdx + 1] || null;
                
                if (newTargetEl) {
                    e.preventDefault();
                    newTargetEl.select();
                }
            }
        }
    }

    handleFocusSelectText(e) {
        e.target.select();
    }

    render() {
        return (
            <div>
                <form onSubmit={this.handleHilineIdSubmit}>
                    <div className="form-group">
                        <label htmlFor="hilineId">HiLine ID</label>
                        <input type="text" className="form-control" name="hilineId"
                            ref={(el) => this.hilineInput = el}
                        />
                    </div>
                    <div className="text-right">
                        <button type="submit" className="btn btn-secondary">Load Item</button>
                    </div>
                </form>
                <form onSubmit={this.handleOrderUpcSubmit} onKeyPress={this.handleKeyPress}>
                    <div className="form-group">
                        <label htmlFor="orderNumber">Order Number</label>
                        <input type="text" className="form-control" name="orderNumber"
                            ref={(el) => this.orderInput = el}
                            onFocus={this.handleFocusSelectText}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="upc">UPC</label>
                        <input type="text" className="form-control" name="upc" 
                            onFocus={this.handleFocusSelectText}
                        />
                    </div>
                    <div className="text-right">
                        <button type="submit" className="btn btn-secondary">Load Item</button>
                    </div>
                </form>
            </div>
        );
    }
}

FilterComponent.propTypes = {
    onOrderUpcSubmit: PropTypes.func.isRequired,
    onHiLineIdSubmit: PropTypes.func.isRequired,
};
