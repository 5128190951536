import React, { Component } from 'react';
import { eBayAccountLogin, verifyEbayToken } from '../../lib/eBay';

export default class EbaySample extends Component {
    constructor(props = {}) {
        super(props);
        this.state = {
            isShowEbayLoginButton: false,
        };
        this.eBayLogin = this.eBayLogin.bind(this);
    }

    async componentDidMount() {
        try {
            const response = await verifyEbayToken();
            this.setState({
                isShowEbayLoginButton: response.isShowEbayLoginButton,
            });
        } catch (error) {
            console.error('error', error); // eslint-disable-line no-console
        }
    }

    async eBayLogin() {
        try {
            const response = await eBayAccountLogin();
            const win = window.open(response.redirectUrl, '_blank');
            win.focus();
        } catch (error) {
            console.error('error', error); // eslint-disable-line no-console
        }
    }

    render() {
        return (
            <div>
                <button
                    className="btn btn-secondary"
                    onClick={this.eBayLogin}
                    disabled={this.state.isShowEbayLoginButton}>Login eBay
                </button>
                <div>
                    {
                        !this.state.isShowEbayLoginButton ?
                            <p className="text-danger">Please Login eBay Account</p>
                            : <p className="text-success">You are connected with eBay</p>
                    }
                </div>
            </div>
        );
    }
}
