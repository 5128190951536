import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class ProductLookupComponent extends Component {
    constructor(props = {}) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }
    handleFormSubmit(e) {
        e.preventDefault();
        let fd = new FormData(e.target);
        this.props.onSubmit(fd.get('input'));
        //e.target.reset();
    }

    render() {
        return (
            <form className="form" onSubmit={this.handleFormSubmit}>
                <div className="form-group">
                    <label htmlFor="input">Hiline ID</label>
                    <input type="text" className="form-control" name="input" />
                </div>
                <button type="submit" className="btn btn-primary">Load Product</button>
            </form>
        );
    }

}

ProductLookupComponent.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};
