/* eslint-disable react/prop-types */
import { SentryErrorBoundary } from './sentry';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Redirect } from 'react-router';
import { ConnectedRouter } from 'react-router-redux';
import { Provider, connect } from 'react-redux';
import { store, history } from './redux';
import authDuck from './redux/ducks/auth';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faSearch } from '@fortawesome/free-solid-svg-icons';

// Styles
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';
import 'react-select/dist/react-select.css';
import 'bootstrap/dist/css/bootstrap.css';
import './style.css';

// Components
import App from './components/App';
import GradingContainer from './components/grading';
import ReceivingContainer from './components/receiving';
import UploadingContainer from './components/upload';
import ReportingContainer from './components/reporting';
import MiscPageContainer from './components/misc';
import EbayPageContainer from './components/ebay';
import Navbar from './components/navbar';
import Alert from 'react-s-alert';

// FontAwesome Setup
library.add(faSearch, faSpinner);

const handleAuthentication = (nextState) => {
    if (/access_token|id_token|error/.test(nextState.location.hash)) {
        store.dispatch(authDuck.creators.handleAuthentication());
    }
};

function Index(props) {
    return (
        <ConnectedRouter history={props.history} component={App} >
            <div>
                <Navbar />
                <div className="container">
                    <Route exact path="/" component={App} />
                    <Route exact path="/home" component={App} />
                    <Route exact path="/receive" render={(routeProps) => {
                        if (!props.isAuthenticated) {
                            return <Redirect to={{ pathname: '/' }} />;
                        }
                        return <ReceivingContainer {...routeProps} />;
                    }} />
                    <Route path="/grading" render={(routeProps) => {
                        if (!props.isAuthenticated) {
                            return <Redirect to={{ pathname: '/' }} />;
                        }
                        return <GradingContainer {...routeProps} />;
                    }} />
                    <Route exact path="/upload" render={(routeProps) => {
                        if (!props.isAuthenticated) {
                            return <Redirect to={{ pathname: '/' }} />;
                        }
                        return <UploadingContainer {...routeProps} />;
                    }} />
                    <Route exact path="/report" render={(routeProps) => {
                        if (!props.isAuthenticated) {
                            return <Redirect to={{ pathname: '/' }} />;
                        }
                        return <ReportingContainer {...routeProps} />;
                    }} />
                    <Route exact path="/misc" render={(routeProps) => {
                        if (!props.isAuthenticated) {
                            return <Redirect to={{ pathname: '/' }} />;
                        }
                        return <MiscPageContainer {...routeProps} />;
                    }} />
                    <Route path="/callback" render={(props) => {
                        handleAuthentication(props);
                        return <Redirect to={{
                            pathname: '/home',
                        }} />;
                    }}

                    />
                    <Route path="/ebay" render={(routeProps) => {
                        if (!props.isAuthenticated) {
                            return <Redirect to={{ pathname: '/' }} />;
                        }
                        return <EbayPageContainer {...routeProps} />;
                    }}

                    />
                    <Alert stack={{ limit: 3 }} position='top' effect='stackslide' timeout={5000} />
                </div>
            </div>
        </ConnectedRouter >
    );
}

const mapStateToProps = (state) => {
    let isAuthenticated = authDuck.selectors.isAuthenticated(state.auth);
    return {
        isAuthenticated: isAuthenticated,
        history,
    };
};

const IndexOutput = connect(
    mapStateToProps,
)(Index);
window.addEventListener('load', () => {
    store.dispatch({ type: 'APP.WINDOW.LOAD' });
});
store.dispatch({ type: 'APP.INIT' });

ReactDOM.render(
    <SentryErrorBoundary>
        <Provider store={store}>
            <IndexOutput />
        </Provider>
    </SentryErrorBoundary>,
    document.getElementById('root'),
);

// registerServiceWorker();
if (navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations()
        .then((registrations) => {
            for (let registration of registrations) {
                registration.unregister();
            }
        });
}
