import React from 'react';

import SingleLabelPrinting from './SingleLabelPrinting';
import WrongProductFixing from './WrongProductFixing';
import QueueStatus from './QueueStatus';
import SellerActiveMinimumPriceQueueStatus from './SellerActiveMinimumPriceQueueStatus';
import PrinterSelectionContainer from '../printerSelectionContainer';

export default function MiscContainer() {

    return (
        <div>
            <PrinterSelectionContainer />
            <h2>Print Labels</h2>
            <SingleLabelPrinting />
            <hr />
            <h2>Fix Product Identity</h2>
            <WrongProductFixing />
            <hr />
            <h2>SellerActive Inventory Update Retry Queue</h2>
            <QueueStatus />
            <hr />
            <h2>SellerActive Minimum Price Inventory Update Retry Queue</h2>
            <SellerActiveMinimumPriceQueueStatus />
        </div>
    );

}
