import React from 'react';
import PropTypes from 'prop-types';

export default class PrinterSelectionComponent extends React.Component {
    constructor(props = {}) {
        super(props);

        this.handlePrinterSelect = this.handlePrinterSelect.bind(this);
    }

    _preventFormSubmit(e) {
        e.preventDefault();
    }

    handlePrinterSelect(e) {
        this.props.onPrinterSelect(e.target.value);
    }

    render() {
        return (
            <form className="form-inline float-md-right" onSubmit={this._preventFormSubmit}>
                <label className="col-form-label">Select Label Printer </label>
                <select className="form-control form-control-sm" onChange={this.handlePrinterSelect} value={this.props.selectedPrinter}>
                    {this.props.printerOptions.map((obj) =>
                        <option key={obj.value} value={obj.value}>{obj.label}</option>,
                    )}
                </select>
            </form>
        );
    }
}

PrinterSelectionComponent.propTypes = {
    onPrinterSelect: PropTypes.func.isRequired,
    printerOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedPrinter: PropTypes.number.isRequired,
};
