import React from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'date-fns/format';

function doCellFormat(cell) {
    if (!cell) return '-';

    if (cell.match(/^(.+)T(.+)Z$/) !== null) {
        let formattedDate = dateFormat(new Date(cell), 'YYYY-MM-DD');
        if (formattedDate !== 'Invalid Date') {
            return formattedDate;
        }
    }
    return cell;
}

export default function ReportingTableComponent(props = {}) {
    return (
        <div className="w-100 mt-2" style={{overflowX: 'auto'}}>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        {(props.rows.length >= 1 && props.rows[0]).map((c, idx) => <th key={idx}>{c}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {props.rows.slice(1).map((row, rowIdx) => (
                        <tr key={rowIdx}>
                            {row.map((c, idx) => <td key={idx}>{doCellFormat(c)}</td>)}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

ReportingTableComponent.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};
