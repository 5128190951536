import React from 'react';
import { call as restCall } from '../../lib/api';

import Alert from 'react-s-alert';
import ReceivingUploadComponent from './uploadComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException } from '../../sentry';


export default class ReceivingUploadContainer extends React.Component {
    constructor(params = {}) {
        super(params);
        this.state = {
            isUploading: false,
        };
        this.handleSpreadsheetUpload = this.handleSpreadsheetUpload.bind(this);
    }
    handleSpreadsheetUpload(formElement) {
        this.setState({ isUploading: true });
        let fd = new FormData(formElement);
        restCall('/api/receiving/upload', 'POST', fd)
            .then(r => {
                if (r && r.success) {
                    Alert.success(r.message, {
                        timeout: 2000,
                    });
                } else if (r) {
                    Alert.error(r.message);
                }
                this.setState({ isUploading: false });
            })
            .catch(err => {
                console.error(err); // eslint-disable-line
                Alert.error(err.message || err.toString());
                this.setState({ isUploading: false });
                captureException(err, {
                    setContext: {
                        fd,
                        component: 'ReceivingUploadContainer',
                    },
                });
            });
    }
    render() {
        return (
            <div>
                {!this.state.isUploading && <ReceivingUploadComponent onUpload={this.handleSpreadsheetUpload} />}
                {this.state.isUploading &&
                    <div className="text-center mt-2">
                        <FontAwesomeIcon icon="spinner" size="4x" spin /><br />
                        <span>Processing</span>
                    </div>}
            </div>
        );
    }
}
