/* eslint-disable no-console */
import Duck from 'extensible-duck';

import Auth0 from 'auth0-js';

// export const AUTH_CONFIG = {
//     domain: 'hiline-electronics.auth0.com',
//     clientId: 'l1GWyJ8jNDMSA80H1iNm39S7Pddbrv-4',
//     callbackUrl: `${window.location.origin}/callback`,
//     apiUrl: 'hiline-receive-grade',
//     scope: 'openid profile email',
// };

export const AUTH_CONFIG = {
    domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
    clientId: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
    callbackUrl: `${window.location.origin}/callback`,
    apiUrl: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
    scope: 'openid profile email',
};


export const auth0 = new Auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: AUTH_CONFIG.apiUrl,
    responseType: 'token id_token',
    scope: AUTH_CONFIG.scope,
});



const authDuck = new Duck({
    namespace: 'my-app', store: 'auth',
    types: [
        'AUTH', 'LOGIN', 'LOGOUT',
        // Sessions
        'SET', 'CLEAR',
        //
        'ERROR',
        //
        'SET_USER_INFO', 'SET_USER_DETAILS',
    ],
    initialState: {
        error: null,
        session: null,
        userInfo: null,
        userDetails: null,
    },
    reducer: (state, action, duck) => {
        switch (action.type) {
        case duck.types.LOGIN:
            auth0.authorize();
            return state;
        case duck.types.AUTH:
            return state;
        case duck.types.SET:
            return Object.assign({}, state, {
                session: {
                    access_token: action.session.access_token,
                    id_token: action.session.id_token,
                    expires_at: action.session.expires_at,
                },
                error: null,
            });
        case duck.types.CLEAR: {
            return {
                session: {},
                error: null,
                userInfo: null,
                userDetails: null,
            };
        }
        case duck.types.SET_USER_INFO: {
            return {
                ...state,
                userInfo: action.userInfo,
            };
        }
        case duck.types.SET_USER_DETAILS: {
            return {
                ...state,
                userDetails: action.userDetails,
            };
        }
        default: return state;
        }
    },
    selectors: {
        root: (state) => state,
        getAccessToken: (state) => (state.session || {}).access_token,
        isAuthenticated: (state) => {
            return new Date().getTime() < ((state.session || {}).expires_at);
        },
        getCurrentUser: (state) => {
            let userInfo = state.userInfo;
            let userDetails = state.userDetails;
            if (!userInfo) return null;
            return {
                username: userInfo.name,
                email: userInfo.email,
                name: userDetails && userDetails.user_metadata && (userDetails.user_metadata.name || null),
                canSeeHelpdeskIntegration: userDetails && userDetails.app_metadata && (userDetails.app_metadata.canSeeHelpdeskIntegration || false),
            };
        },
    },
    creators: (duck) => ({
        setSession: (session) => ({ type: duck.types.SET, session }),
        clearSession: () => ({ type: duck.types.CLEAR }),
        setError: (err) => ({ type: duck.types.ERROR, error: err }),
        handleAuthentication: handleAuthentication,
        login: () => ({ type: duck.types.LOGIN }),
        logout: () => duck.creators.clearSession(),
        loadUserInfo: loadUserInfo,
        setUserInfo: (userInfo) => ({ type: duck.types.SET_USER_INFO, userInfo }),
        setUserDetails: (userDetails) => ({ type: duck.types.SET_USER_DETAILS, userDetails }),
        checkSession: () => handleCheckSession,
    }),
});

export default authDuck;

function handleAuthentication() {

    return function (dispatch) {

        return auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                dispatch(authDuck.creators.setSession(_buildSession(authResult)));
            } else if (err) {
                dispatch(authDuck.creators.setError(err.error));
            }
        });
    };
}

function loadUserInfo() {
    return function (dispatch, getState) {

        let state = getState();
        let accessToken = state.auth && state.auth.session && state.auth.session.access_token;
        let userInfo = state.auth.userInfo;

        if (!accessToken) return;
        if (userInfo) return;
        let tokenId = state.auth.session.id_token;

        dispatch(authDuck.creators.setUserInfo({}));

        return auth0.client.userInfo(accessToken, function (err, user) {
            if (user) {
                dispatch(authDuck.creators.setUserInfo(user));

                let auth0Manage = new Auth0.Management({
                    domain: AUTH_CONFIG.domain,
                    token: tokenId,
                });

                auth0Manage.getUser(user.sub, function (err, userDetails) {
                    if (userDetails) {
                        dispatch(authDuck.creators.setUserDetails(userDetails));
                    } else if (err) {
                        dispatch(authDuck.creators.setError(err.error));
                    }
                });
            } else if (err) {
                dispatch(authDuck.creators.setError(err.error));
            }
        });
    };
}

function handleCheckSession() {
    return new Promise(function (resolve) {
        auth0.checkSession({
            audience: AUTH_CONFIG.apiUrl,
            scope: AUTH_CONFIG.scope,
            redirectUri: AUTH_CONFIG.callbackUrl,
        }, function (err, authResult) {
            if (authResult && authResult.accessToken && authResult.idToken) {
                resolve(authDuck.creators.setSession(_buildSession(authResult)));
            } else if (err) {
                resolve(authDuck.creators.setError(err.error));
            }
        });
    });
}
window.handleCheckSession = handleCheckSession;

function _buildSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
        authResult.expiresIn * 1000 + new Date().getTime(),
    );

    return {
        access_token: authResult.accessToken,
        id_token: authResult.idToken,
        expires_at: expiresAt,
    };
}
