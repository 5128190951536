import React from 'react';
import PropTypes from 'prop-types';

function findForm(el) {
    let node = el;
    while (node && node.tagName !== 'BODY' && node.tagName !== 'FORM') {
        node = node.parentNode;
    }

    if (node.tagName !== 'FORM') {
        return null;
    }
    return node;
}

export default function ReceivingUploadComponent(props = {}) {
    return (
        <form>
            <div className="form-group">
                <label htmlFor="name">Spreadsheet</label>
                <input type="file" className="form-control-file" name="file" />
            </div>
            <div className="form-group">
                <button type="button" className="btn btn-primary" onClick={(e) => props.onUpload(findForm(e.target))}>Upload Spreadsheet</button>
            </div>
        </form>
    );
}

ReceivingUploadComponent.propTypes = {
    onUpload: PropTypes.func.isRequired,
};
