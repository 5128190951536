import React from 'react';
import {call as restCall} from '../../../lib/api';

import Alert from 'react-s-alert';
import SingleLabelPrintingComponent from './SingleLabelPrintingComponent';

export default class SingleLabelPrintingContainer extends React.Component {
    constructor(props = {}) {
        super(props);
        this.handlePrintLabel = this.handlePrintLabel.bind(this);
    }
    handlePrintLabel(hilineOrSerialNumber) {
        restCall(`/api/inventory/print/${hilineOrSerialNumber.trim()}`)
            .then((r) => {
                if (r && r.success) {
                    Alert.success('Label printed');
                } else if (r && !r.item) {
                    Alert.error('No matching item was found');
                } else {
                    Alert.error('An error occurred attempting to print the label');
                }
            });
    }
    render() {
        return (
            <SingleLabelPrintingComponent onSubmit={this.handlePrintLabel} />
        );
    }
}
