import { call as apiCall } from './api';
import dateFormat from 'date-fns/format';
import addDay from 'date-fns/add_days';

export function buildReportUrl(params = {}) {
    let url = new URL('/api/reporting/bulk', window.location.origin);

    if (params.startDate) {
        let d = addDay(new Date(params.startDate), 1);
        params.startDate = dateFormat(d, 'YYYY-MM-DDT00:00:00.000') + 'Z';
    }
    if (params.endDate) {
        let d = addDay(new Date(params.endDate), 1);
        params.endDate = dateFormat(d, 'YYYY-MM-DDT23:59:59.999') + 'Z';
    }

    params.tzOffsetMinutes = (new Date()).getTimezoneOffset() * -1;

    for (var k in params) {
        if (Object.prototype.hasOwnProperty.call(params, k)) {
            url.searchParams.set(k, params[k]);
        }
    }

    return url;
}
export async function loadReport(params = {}) {

    let url = buildReportUrl(params);
    let resp = await apiCall(url);

    return resp;
}
