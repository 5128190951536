import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-s-alert';

export default class ProductEditComponent extends Component {
    constructor(props = {}) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }
    validateUpc(upc) {
        let valid = /^\d{11,}$/.test(upc.trim());
        if (!valid) {
            Alert.error('The UPC appears to be invalid');
        }

        return valid;
    }
    validateDescription(desc) {
        let valid = desc.trim().length === 0 || desc.trim().length <= 255;
        if (!valid) {
            Alert.error('The description appears to be invalid, it is required and cannot be longer than 255 characters');
        }

        return valid;
    }
    handleFormSubmit(e) {
        e.preventDefault();
        let fd = new FormData(e.target);
        if (!this.validateUpc(fd.get('upc')) || !this.validateDescription(fd.get('description'))) {
            return;
        }

        this.props.onSubmit({
            upc: fd.get('upc'),
            description: fd.get('description'),
        });
        //e.target.reset();
    }

    render() {
        return (
            <form className="form" onSubmit={this.handleFormSubmit}>
                <div className="form-group">
                    <label>Hiline ID: {this.props.hilineId}</label>
                </div>
                <div className="form-group">
                    <label htmlFor="upc">Corrected UPC</label>
                    <input type="text" className="form-control" name="upc"/>
                    <small className="form-text text-muted">
                        Was &quot;{this.props.upc}&quot;
                    </small>
                </div>
                <div className="form-group">
                    <label htmlFor="description">Corrected Description</label>
                    <input type="text" className="form-control" name="description"/>
                    <small className="form-text text-muted">
                        Was &quot;{this.props.description}&quot;
                    </small>
                </div>
                <button type="submit" className="btn btn-primary">Save</button>
                <button type="button" className="btn btn-secondary" onClick={this.props.onCancel}>Cancel</button>
            </form>
        );
    }

}

ProductEditComponent.propTypes = {
    hilineId: PropTypes.string.isRequired,
    upc: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
