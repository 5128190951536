import React from 'react';

import LookupContainer from './lookup/lookupContainer';
import PrinterSelectionContainer from '../printerSelectionContainer';

export default function ReceivingContainer() {

    return (
        <div>
            <PrinterSelectionContainer />
            <h2>Receive Items</h2>
            <p>Scan a HiLine ID or select from the table to receive items and print barcodes.</p>
            <hr />
            <LookupContainer />
        </div>
    );

}
