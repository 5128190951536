import Alert from 'react-s-alert';
import {call as apiCall} from './api';
import DEV_URL from '../urls';




export async function receiveItem(params = {}) {
    let url = '/api/receiving/receive/';

    if (params.hilineId) {
        url += `hiline/${params.hilineId}`;
    } else if (params.id) {
        url += params.id;
    } else {
        return false;
    }

    let response = await apiCall(url, 'PUT');

    if (response.success) {
        Alert.success('Item received successfully', {
            timeout: 2000,
        });
        return response.data;
    }

    console.error(response);// eslint-disable-line no-console
    Alert.error(response.message || 'An error occured receiving the item');
    return false;
}

export async function loadItems(params = {}) {
    let url;
    //let url = new URL('/api/receiving/unreceived', process.env.NODE_ENV === "production" ? window.location.origin : DEV_URL);
    //let url = '/api/receiving/unreceived'
    if (process.env.NODE_ENV === 'production')
        url = new URL('/api/receiving/unreceived', window.location.origin);
    else
        url = new URL('/api/receiving/unreceived', DEV_URL);

    for (var k in params) {
        if (Object.prototype.hasOwnProperty.call(params, k)) {
            url.searchParams.set(k, params[k]);
        }
    }
    
    let response = await apiCall(url);

    if (response.success) {
        return response.data;
    }

    console.error(response);// eslint-disable-line no-console
    Alert.error(response.message || 'An error occured retrieving unreceived items');

    return false;
}
