import React from 'react';
import * as PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

const isLocal = window.location.hostname === 'localhost';
const isDev = window.location.hostname.includes('dev');
const isBeta = window.location.hostname.includes('beta');

let environment = 'production';
if (isDev) {
    environment = 'development/testing';
} else if (isBeta) {
    environment = 'beta';
} else if (isLocal) {
    environment = 'local';
}


if (!isLocal) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        release: `hiline-tnr-frontend@${process.env.REACT_APP_GIT_HASH || 'unknown'}`,
        environment,
    });
}

export const captureException = Sentry.captureException;
export const captureMessage = Sentry.captureMessage;

export const SentryErrorBoundary = ({ children }) => (
    <Sentry.ErrorBoundary fallback={<div>An error has occured</div>} showDialog>
        {children}
    </Sentry.ErrorBoundary>
);
SentryErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};
