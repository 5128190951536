import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import authDuck from '../redux/ducks/auth';

//
// Patch Bootstrap Collapse
const CLASS_SHOW = 'show';
const CLASS_COLLAPSE = 'collapse';
const CLASS_COLLAPSING = 'collapsing';
const CLASS_COLLAPSED = 'collapsed';
const ANIMATION_TIME = 350; // 0.35s

function handleCollapseElementClick(e) {
    let el = e.currentTarget;
    let collapseTargetId = el.dataset.target || el.href || null;
    if (collapseTargetId) {
        let targetEl = document.querySelector(collapseTargetId);
        let isShown = targetEl.classList.contains(CLASS_SHOW) || targetEl.classList.contains(CLASS_COLLAPSING);
        if (!isShown) {
            targetEl.classList.remove(CLASS_COLLAPSE);
            targetEl.classList.add(CLASS_COLLAPSING);
            targetEl.style.height = 0;
            targetEl.classList.remove(CLASS_COLLAPSED);
            setTimeout(() => {
                targetEl.classList.remove(CLASS_COLLAPSING);
                targetEl.classList.add(CLASS_COLLAPSE, CLASS_SHOW);
                targetEl.style.height = '';
            }, ANIMATION_TIME);
            targetEl.style.height = targetEl.scrollHeight + 'px';
        } else {
            targetEl.style.height = `${targetEl.getBoundingClientRect().height}px`;
            // force reflow
            targetEl.offsetHeight; // eslint-disable-line no-unused-expressions
            targetEl.classList.add(CLASS_COLLAPSING);
            targetEl.classList.remove(CLASS_COLLAPSE, CLASS_SHOW);
            targetEl.style.height = '';
            setTimeout(() => {
                targetEl.classList.remove(CLASS_COLLAPSING);
                targetEl.classList.add(CLASS_COLLAPSE);
            }, ANIMATION_TIME);
        }
    }
}

//

export class NavbarComponent extends Component {
    componentDidMount() {
        if (this.props.isAuthenticated && !this.props.userInfo) {
            this.props.loadUserInfo();
        }

        this.navbarToggleEl.addEventListener('click', handleCollapseElementClick);
        this.navbarEl.addEventListener('click', (e) => {
            let togglerDisplay = window.getComputedStyle(this.navbarToggleEl).display;
            if (togglerDisplay !== 'none' && e.path && e.path.includes(this.navbarToggleEl) === false) {
                this.navbarToggleEl.click();
            }
        });
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (nextProps.isAuthenticated && !nextProps.userInfo) {
            this.props.loadUserInfo();
        }
    }

    render() {

        return (
            <nav ref={(el) => this.navbarEl = el} className="navbar navbar-expand-lg navbar-light bg-light mb-3">
                <button ref={(el) => this.navbarToggleEl = el} className="navbar-toggler" type="button"
                    data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">Home</Link>
                        </li>
                        {this.props.isAuthenticated &&
                        <li className="nav-item">
                            <Link className="nav-link" to="/upload">Upload Spreadsheet</Link>
                        </li>
                        }
                        {this.props.isAuthenticated &&
                        <li className="nav-item">
                            <Link className="nav-link" to="/receive">Receiving</Link>
                        </li>
                        }
                        {this.props.isAuthenticated &&
                        <li className="nav-item">
                            <Link className="nav-link" to="/grading">Grading</Link>
                        </li>
                        }
                        {this.props.isAuthenticated &&
                        <li className="nav-item">
                            <Link className="nav-link" to="/report">Reports</Link>
                        </li>
                        }
                        {this.props.isAuthenticated &&
                        <li className="nav-item">
                            <Link className="nav-link" to="/misc">Misc</Link>
                        </li>
                        }
                        {this.props.isAuthenticated &&
                        <li className="nav-item">
                            <Link className="nav-link" to="/ebay">Ebay</Link>
                        </li>
                        }
                    </ul>
                    {
                        this.props.isAuthenticated && this.props.userInfo &&
                        <span className="navbar-text mr-1">
                            Logged In as {this.props.userInfo.name || this.props.userInfo.username}
                        </span>
                    }
                    {
                        this.props.isAuthenticated &&
                        <span className="navbar-text d-block">
                            <button className="btn btn-secondary btn-sm" onClick={this.props.logout}>Logout</button>
                        </span>
                    }
                </div>
            </nav>
        );
    }
}

const mapStateToProps = state => {

    return {
        isAuthenticated: authDuck.selectors.isAuthenticated(state.auth),
        userInfo: authDuck.selectors.getCurrentUser(state.auth) || null,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(authDuck.creators.logout()),
        loadUserInfo: () => dispatch(authDuck.creators.loadUserInfo()),
    };
};

const NavbarComponentOutput = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavbarComponent);

export default NavbarComponentOutput;

NavbarComponent.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    userInfo: PropTypes.shape({
        name: PropTypes.string,
        username: PropTypes.string,
        canSeeHelpdeskIntegration: PropTypes.bool,
    }),
    loadUserInfo: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
};
