import Alert from 'react-s-alert';
import { call as apiCall } from './api';
import DEV_URL from '../urls';

/**
 * Verify ebay access token
 */
export async function verifyEbayToken() {
    let url = new URL('/api/authorize/verify', window.location.origin);
    let response = await apiCall(url);
    if (response.success) {
        return response.data;
    }
    console.error(response); // eslint-disable-line no-console
    Alert.error(response.message || 'An error occurred for verify eBay token');
    return false;
}

/**
 * Login into ebay account
 */
export async function eBayAccountLogin() {
    let url = new URL('/api/authorize/auth/url', window.location.origin);
    let response = await apiCall(url);
    if (response.success) {
        return response.data;
    }
    console.error(response); // eslint-disable-line no-console
    Alert.error(response.message || 'An error occurred for get eBay redirect link');
    return false;
}

/**
 * Get the root categories of ebay
 * @returns {object[]}
 */
export async function getRootCategories() {
    let url;
    if (process.env.NODE_ENV === 'production')
        url = new URL('/api/grading/categories/root', window.location.origin);
    else
        url = new URL('/api/grading/categories/root', DEV_URL);
    let response = await apiCall(url);
    if (response.success) {
        return response.data;
    }
    console.error(response); // eslint-disable-line no-console
    Alert.error(response.message || 'Unable to fetch categories. Please try again later!');
    return false;
}

/**
 * Get the child categories oof ebay
 * @param {number} rootCategory
 * @returns {object[]}
 */
export async function getChildCategories(rootCategory) {
    let url = new URL(`/api/grading/categories/child/${rootCategory}`, window.location.origin);
    let response = await apiCall(url);
    if (response.success) {
        return response.data;
    }
    console.error(response); // eslint-disable-line no-console
    Alert.error(response.message || 'Unable to fetch categories. Please try again later!');
    return false;
}

/**
 * Check if item is exists on ebay
 * @param {number} itemId
 */
export async function checkItemExistsOnEbay(itemId) {
    if (!itemId) {
        Alert.error('No item id was included for listing');
        return false;
    }
    let url = new URL(`/api/listing/check/${itemId}`, window.location.origin);
    let response = await apiCall(url);
    if (response.success) {
        return response.data;
    }
    console.error('response', response); // eslint-disable-line no-console
    Alert.error(response.message || 'An error occurred while listing the item on ebay');
    return response.data;
}

/**
 * Update inventory item quantity
 * @param {object} item
 */
export async function updateItemQuantity(item) {
    if (!item) {
        Alert.error('No item was provided for update quantity');
        return false;
    }

    let url = new URL('/api/listing/update', window.location.origin);

    let response = await apiCall(url, 'PUT', item);

    if (response.success) {
        Alert.success(response.message);
        return true;
    }

    console.error('response', response); // eslint-disable-line no-console
    Alert.error(response.message || 'An error occurred while listing the item on ebay');

    return false;

}

/**
 * Create a new item listing on eBay
 * @param {object} item
 */
export async function createListing(item) {
    if (!item) {
        Alert.error('No item id was included for listing');
        return false;
    }

    let url = new URL('/api/listing/create', window.location.origin);

    let response = await apiCall(url, 'POST', item);

    if (response.success) {
        Alert.success(response.message);
        return response.data;
    }

    console.error('response', response); // eslint-disable-line no-console
    Alert.error(response.message || 'An error occurred while listing the item on ebay');

    return false;

}

/**
 * Search the item by selected leaf category
 * @param {object} category
 */
export async function searchBySelectedLeafCategory(category) {
    if (!category) {
        Alert.error('No selected leaf category');
        return false;
    }

    let url = new URL('/api/listing/category/search', window.location.origin);

    let response = await apiCall(url, 'PUT', category);

    if (response.success) {
        return response.data;
    }

    console.error('response', response); // eslint-disable-line no-console
    Alert.error(response.message || 'An error occurred while listing the item on ebay');

    return false;

}
