import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import Alert from 'react-s-alert';

import { loadItemForGrading } from '../../lib/grading';
import LookupContainer from './lookup/lookupContainer';
import GradingContainer from './grade/gradeContainer';
import PrinterSelectionContainer from '../printerSelectionContainer';

export default class GradingIndex extends React.Component {
    constructor(props = {}) {
        super(props);

        this.state = {
            item: null,
        };

        this.handleLoadedItem = this.handleLoadedItem.bind(this);
        this.handleItemLookup = this.handleItemLookup.bind(this);
    }

    handleLoadedItem(item) {
        console.log('item', item); // eslint-disable-line
        this.setState({ item });
        this.props.history.push(`/grading/${item.id}`);
    }

    handleItemLookup(lookupParams, bypassHilineCheck = false) {
        return loadItemForGrading(lookupParams, bypassHilineCheck)
            .then((item) => {
                if (!item) {
                    // false = errored already, null = none found
                    if (item === null) {
                        Alert.error('No matching ungraded items were found');
                    }
                    return false;
                }
                return item;
            });
    }

    render() {
        return (
            <div>
                <PrinterSelectionContainer />
                <Route exact path={`${this.props.match.url}`} render={() =>
                    <LookupContainer
                        onItemLookup={this.handleItemLookup}
                        onLoadedItem={this.handleLoadedItem}
                    />
                } />
                <Route exact path={`${this.props.match.url}/:itemId`} render={(props) =>
                    <GradingContainer
                        {...props}
                        item={this.state.item}
                        onAttemptLoadItem={(itemId) => this.handleItemLookup({ id: itemId }, true)}
                    />
                } />
            </div>
        );
    }
}

GradingIndex.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};
