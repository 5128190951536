/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import authDuck from '../redux/ducks/auth';


export class App extends Component {
    goTo(route) {
        this.props.history.replace(`/${route}`);
    }

    login() {
        this.props.login();
    }

    logout() {
        this.props.logout();
    }

    render() {

        return (
            <div>
                <p>
                    Welcome to the Hiline Electronics Receiving &amp; Grading Application
                </p>
                {!this.props.isAuthenticated &&
                    <button className="btn btn-primary btn-lg btn-block" onClick={() => this.login()}>Login</button>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    
    return {
        isAuthenticated: authDuck.selectors.isAuthenticated(state.auth),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: () => dispatch(authDuck.creators.login()),
        logout: () => dispatch(authDuck.creators.logout()),
    };
};

const AppOutput = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);

export default AppOutput;
