import Alert from 'react-s-alert';
import { call as apiCall } from './api';
import DEV_URL from '../urls';

let conditionsCache = null;
let locationsCache = null;

export async function loadItemForGrading(
    params = {},
    bypassHilineCheck = false,
) {
    let url;
    if (process.env.NODE_ENV === 'production')
        url = new URL('/api/inventory', window.location.origin);
    else
        url = new URL('/api/inventory', DEV_URL);


    for (var k in params) {
        if (Object.prototype.hasOwnProperty.call(params, k)) {
            url.searchParams.set(k, params[k]);
        }
    }

    let response = await apiCall(url);

    if (response.success) {
        if (response.data.length === 0) {
            Alert.error('No matching items found');
            return false;
        }

        // Filter it down to only non-graded
        let items = response.data.filter(
            (item) => item.gradedAt === null && item.gradedBy === null,
        );

        // If we only have items with hiline ids and none was provided
        //  then we need to force those to be used!
        if (!params.hilineId && !bypassHilineCheck) {
            items = items.filter((item) => item.hilineId === null);

            if (items.length === 0) {
                Alert.error(
                    'Only items with HiLine IDs were found, you must use that',
                );
                return false;
            }
        }
        let item = items[0];
        // if (item && response.lastMinPrice) {
        //     item["lastMinPrice"] = response.lastMinPrice;
        // }
        if (item && response.lastFbaMinPrice) {
            item['lastFbaMinPrice'] = response.lastFbaMinPrice;
        }
        if (item && response.lastNonFbaMinPrice) {
            item['lastNonFbaMinPrice'] = response.lastNonFbaMinPrice;
        }
        return item || null;
    }

    console.error(response); // eslint-disable-line no-console
    Alert.error(response.message || 'An error occured load items');

    return false;
}

export async function loadConditions() {
    if (conditionsCache) return conditionsCache;
    let url;
    if (process.env.NODE_ENV === 'production')
        url = new URL('/api/inventory/conditions', window.location.origin);
    else
        url = new URL('/api/inventory/conditions', DEV_URL);

    let response = await apiCall(url);

    if (response.conditions) {
        conditionsCache = response.conditions;
        return response.conditions;
    }
    return false;
}

export async function loadLocations() {
    if (locationsCache) return locationsCache;
    let url;
    if (process.env.NODE_ENV === 'production')
        url = new URL('/api/inventory/locations', window.location.origin);
    else
        url = new URL('/api/inventory/locations', DEV_URL);

    let response = await apiCall(url);

    if (response.locations) {
        locationsCache = response.locations;
        return response.locations;
    }
    return false;
}

export function validateGrade(gradeData) {
    // Must have condition selected
    if (!gradeData.condition) {
        Alert.error('A condition must be selected');
        return false;
    }
    // Must have location
    if (!gradeData.location) {
        Alert.error('A location must be selected');
        return false;
    }
    // Must have something serial number related
    if (gradeData.hasSerialNumber && !(gradeData.serialNumber || '').trim()) {
        Alert.error('A serial number must be entered');
        return false;
    }

    return true;
}

export async function gradeItem(gradeData) {
    if (!gradeData.id) {
        Alert.error('No item id was included with the grade');
        return false;
    }
    let url; 
    if (process.env.NODE_ENV === 'production')
        url = new URL(
            `/api/grading/grade/${gradeData.id}`,
            window.location.origin,
        );
    else
        url = new URL(`/api/grading/grade/${gradeData.id}`, DEV_URL);

    let response = await apiCall(url, 'PUT', gradeData);

    if (response.success) {
        Alert.success(response.message || 'Item graded successfully', {
            timeout: 2000,
        });
        return response.data;
    }

    console.error('response gradeItem', response); // eslint-disable-line no-console
    Alert.error(response.message || 'An error occured grading the item');

    return response.data;
}

/**
 * get product of a upc in BigCommerce
 * @param {String} upc
 * @returns {Promise<Object>}
 */
export async function getProduct(upc) {
    if (!upc) {
        Alert.error('Invalid upc');
        return false;
    }
    let url;
    if (process.env.NODE_ENV === 'production')
        url = new URL(
            `/api/vendor/bigcommerce/product/${upc}`,
            window.location.origin,
        );
    else
        url = new URL(
            `/api/vendor/bigcommerce/product/${upc}`,
            DEV_URL,
        );

    let response = await apiCall(url);

    if (response && response.data && response.data[0]) {
        return response.data[0];
    }
    // Alert.error(response.message || 'An error occurred get product details using UPC.');
    return false;
}

/**
 * calculate Minimum Price
 * @param {Number}  weight
 * @param {Number}  defaultPrice
 * @return {Number}
 */
export function calculateMinimumPrice(weight, defaultPrice, isFBA) {
    let defaultFloorPrice = defaultPrice;
    if (isFBA) {
        //the price is (“Like New Price” x 1.5) 
        defaultFloorPrice = defaultFloorPrice * 1.5;
    } else {
        if (weight < 8) {
            defaultFloorPrice = 4.99;
        } else if (weight < 16) {
            defaultFloorPrice = 5.99;
        } else if (weight < 48) {
            defaultFloorPrice = 11.99;
        } else if (weight < 80) {
            defaultFloorPrice = 16.99;
        } else if (weight < 160) {
            defaultFloorPrice = 21.99;
        } else if (weight < 240) {
            defaultFloorPrice = 26.99;
        } else if (weight < 400) {
            defaultFloorPrice = 34.99;
        } else if (weight < 600) {
            defaultFloorPrice = 49.99;
        } else if (weight < 800) {
            defaultFloorPrice = 59.99;
        }
    }
    return defaultFloorPrice;
}
