import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ProductComponent from '@butztech/hiline-product-details';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loadProductCategories, loadProductModel, saveProductModel, loadProductBrands } from '../../lib/productImport';
import Alert from 'react-s-alert';
import _cloneDeep from 'lodash/cloneDeep';
import { captureException } from '../../sentry';
export default class ProductBuilder extends Component {
    constructor(props = {}) {
        super(props);
        this.state = {
            isSaving: false,
        };

        this.handleSaveProduct = this.handleSaveProduct.bind(this);
    }

    componentDidMount() {
        Promise.all([
            loadProductCategories(),
            loadProductBrands(),
            loadProductModel(this.props.name, this.props.upc, {
                uploadPrice: this.props.uploadPrice || null,
                uploadComparePrice: this.props.uploadComparePrice || null,
                uploadASIN: this.props.asin || null,
                likeNewPrice: this.props.likeNewPrice || null,
                newPrice: this.props.newPrice || null,
            }, this.props.id),
        ])
            .then(([categoriesArray, brandsArray, productModel]) => {
                brandsArray = brandsArray.sort(function (a, b) {
                    let textA = a.name.toUpperCase();
                    let textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                this.setState({
                    bigCommerceCategories: categoriesArray,
                    bigCommerceBrands: brandsArray,
                    productModel,
                    productDesc: productModel.body_html,
                });
            });
    }

    handleSaveProduct(error, productModelData) {
        if (error) {
            console.error(error); // eslint-disable-line no-console
            Alert.error(error.toString().replace(/^Error:/, 'Product Creation Error:'));
            return;
        }

        this.setState((state) => ({
            ...state,
            isSaving: true,
        }));

        let metaProductModal = Object.assign({
            _origUpc: this.props.upc,
            _origName: this.props.name,
        }, _cloneDeep(productModelData));
        saveProductModel(metaProductModal)
            .then((result) => {
                this.setState((state) => ({
                    ...state,
                    isSaving: false,
                }));
                if (result) {
                    this.props.onProductSaveSuccess();
                } else {
                    console.error('Error creating product', { // eslint-disable-line no-console
                        result,
                        productModal: metaProductModal,
                        timestamp: Date.now(),
                    });
                    Alert.error('An unknown error occurred while saving the product');
                }
            })
            .catch((error) => {
                console.error(error); // eslint-disable-line no-console
                Alert.error(error.toString().replace(/^Error:/, 'Product Creation Error:'));
                captureException(error, {
                    setContext: {
                        productModelData, metaProductModal,
                        component: 'ProductBuilder',
                    },
                });
            });
    }

    render() {
        if (this.state.productModel) {
            return (
                <Fragment>
                    <div className="text-center mt-2" style={{ display: this.state.isSaving ? 'inherit' : 'none' }}>
                        <FontAwesomeIcon icon="spinner" size="4x" spin /><br />
                        <span>Saving, <i>do not</i> close this window</span>
                    </div>
                    <div style={{ display: this.state.isSaving ? 'none' : 'inherit' }}>
                        <ProductComponent
                            productModel={this.state.productModel}
                            categoryOptions={this.state.bigCommerceCategories}
                            brandOptions={this.state.bigCommerceBrands}
                            generatedDescription={this.state.productDesc}
                            onSaveProduct={this.handleSaveProduct}
                        />
                    </div>
                </Fragment>
            );
        }

        return (
            <div className="text-center mt-2">
                <FontAwesomeIcon icon="spinner" size="4x" spin /><br />
                <span>Loading</span>
            </div>
        );
    }
}

ProductBuilder.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    upc: PropTypes.string.isRequired,
    uploadPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    uploadComparePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    asin: PropTypes.string,
    onProductSaveSuccess: PropTypes.func.isRequired,
    likeNewPrice: PropTypes.number,
    newPrice: PropTypes.number,
};

//ProductBuilder.defaultProps = {
//    upc: '841163066355',
//    name: 'test',
//    uploadPrice: 101,
//    uploadComparePrice: 201,
//};
