import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ConditionComponent from './conditionComponent';

export default class GradingComponent extends React.Component {
    constructor(props = {}) {
        super(props);

        this.state = {
            locationValue: null, // Needed for React-Select?
            locationOptions: props.locations.map((l) => ({
                label: l,
                value: l,
            })),
            allInfo: props.infoLabels || [],
            allWarn: props.warnLabels || [],
            minimumPrice: props.item.minimumPrice, // For minimum price
            isMinimumPriceSet: false, // For minimum price
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleNoSerialChange = this.handleNoSerialChange.bind(this);
        this.handleSerialNumberChange = this.handleSerialNumberChange.bind(
            this,
        );
        this.handleLocationUpdate = this.handleLocationUpdate.bind(this);
        this.handleMinimumPriceChange = this.handleMinimumPriceChange.bind(
            this,
        );
        this.handleIsFBAChange = this.handleIsFBAChange.bind(this);
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    _extractSerialNumberEls() {
        return {
            input: this.formEl.querySelector('input[name="serialNumber"]'),
            checkbox: this.formEl.querySelector('input[name="noSerialNumber"]'),
        };
    }

    handleFormSubmit(e) {
        this.props.onValidateSerialNumber(this._extractSerialNumberEls());
        this.formEl.classList.add('was-validated');
        e.preventDefault();
        this.props.onGradeSubmit(e);
    }

    handleNoSerialChange(e) {
        let serialNumberEl = this.formEl.querySelector(
            'input[name="serialNumber"]',
        );
        serialNumberEl.value = '';

        if (e.target.checked) {
            serialNumberEl.readOnly = true;
        } else {
            serialNumberEl.readOnly = false;
        }

        this.props.onValidateSerialNumber(this._extractSerialNumberEls());
    }

    handleSerialNumberChange() {
        this.props.onValidateSerialNumber(this._extractSerialNumberEls());
    }

    handleIsFBAChange(e) {
        // IF SEND TO FBA IS CHECKED, do not send to amazon and eBay is also checked.
        if (e.target.checked) {
            let doNotSend = this.formEl.querySelector(
                'input[name="doNotSend"]',
            );
            if (!doNotSend.checked) doNotSend.checked = true;
        }
        this.props.calculateMinimumPriceIfFBA(e.target.checked);
    }

    handleSerialNumberKeypress(e) {
        if (
            [10, 13, 'Enter'].includes(
                e.key || e.char || e.keyCode || e.charCode || e.which,
            )
        ) {
            e.preventDefault();
        }
    }

    handleLocationUpdate(v) {
        this.setState((prevState) => ({
            ...prevState,
            locationValue: v,
        }));
    }

    /**
     * Handle event to changes minimum price
     */
    handleMinimumPriceChange(event) {
        this.setState({
            minimumPrice: event.target.value,
            isMinimumPriceSet: true,
        });
    }

    scrollToBottom() {
        this.pageEnd.scrollIntoView({ behavior: 'smooth' });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.isMinimumPriceSet) {
            return;
        }
        this.setState({ minimumPrice: nextProps.item.minimumPrice });
    }

    render() {
        return (
            <form
                ref={(el) => {
                    this.formEl = el;
                }}
                onSubmit={this.handleFormSubmit}
            >
                <input type="hidden" name="itemId" value={this.props.item.id} />
                <p>
                    <span>
                        <strong>Item Description</strong>{' '}
                        {this.props.item.description}
                    </span>
                    <br />
                    <span>
                        <strong>UPC</strong> {this.props.item.upc}
                    </span>
                    <br />
                    {this.props.item.hilineId && (
                        <span>
                            <strong>Hiline ID</strong>{' '}
                            {this.props.item.hilineId}
                        </span>
                    )}
                    {this.props.item.hilineId && <br />}
                    <span>
                        <strong>Order Number</strong>{' '}
                        {this.props.item.orderNumber}
                    </span>
                    <br />
                    <span>
                        <strong>Vendor</strong> {this.props.item.vendor}
                    </span>
                    <br />
                </p>
                <fieldset className="form-group">
                    <label htmlFor="condition">Condition</label>
                    {this.props.conditions.map((c) => (
                        <ConditionComponent key={c.abbr} {...c} />
                    ))}
                </fieldset>
                <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <Select
                        name="location"
                        value={this.state.locationValue}
                        options={this.state.locationOptions}
                        required
                        className="col-md-4"
                        onChange={this.handleLocationUpdate}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="serialNumber">Serial Number</label>
                    <input
                        type="text"
                        className="form-control col-md-4"
                        name="serialNumber"
                        onChange={this.handleSerialNumberChange}
                        onKeyPress={this.handleSerialNumberKeypress}
                        required
                    />
                    <div className="form-check">
                        <label className="form-check-label">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="noSerialNumber"
                                value=""
                                onChange={this.handleNoSerialChange}
                            />
                            <span>No Serial Number</span>
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="comments">Comments</label>
                    <textarea
                        className="form-control"
                        name="comments"
                        rows="3"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label className="form-check-label">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="doNotSend"
                            value=""
                        />
                        <span>
                            <strong>Do not send to Amazon and eBay</strong>
                        </span>
                    </label>
                </div>
                <div className="form-group">
                    <label className="form-check-label">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="fba"
                            onChange={this.handleIsFBAChange}
                        />
                        <span>
                            <strong>Send to FBA</strong>
                        </span>
                    </label>
                </div>

                <div className="form-group">
                    <label htmlFor="minimumPrice">Minimum Price</label>
                    <input
                        className="form-control col-md-4"
                        type="number"
                        name="minimumPrice"
                        value={this.state.minimumPrice}
                        onChange={this.handleMinimumPriceChange}
                    />
                </div>

                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                        this.formEl.classList.add('was-validated');
                    }}
                >
                    Grade Item
                </button>
                {
                    <div className="row mt-3">
                        {this.state.allInfo.map((value, index) => (
                            <span
                                className="badge badge-warning col-sm-12 mt-2"
                                key={index}
                            >
                                <h4>{value}</h4>
                            </span>
                        ))}
                        {this.state.allWarn.map((value, index) => (
                            <span
                                className="badge badge-primary col-sm-12 mt-2"
                                key={index}
                            >
                                <h4>{value}</h4>
                            </span>
                        ))}
                    </div>
                }
                <div
                    ref={(el) => {
                        this.pageEnd = el;
                    }}
                ></div>
            </form>
        );
    }
}

GradingComponent.propTypes = {
    item: PropTypes.object.isRequired,
    conditions: PropTypes.arrayOf(PropTypes.object).isRequired,
    locations: PropTypes.arrayOf(PropTypes.string).isRequired,
    onGradeSubmit: PropTypes.func.isRequired,
    onValidateSerialNumber: PropTypes.func.isRequired,
    onGraded: PropTypes.bool,
    infoLabels: PropTypes.arrayOf(PropTypes.string),
    warnLabels: PropTypes.arrayOf(PropTypes.string),
    calculateMinimumPriceIfFBA: PropTypes.func.isRequired,
};
