/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

export default function ListRowComponent(props = {}) {
    return (
        <tr>
            <td className="d-none d-md-table-cell">{props.item.upc}</td>
            <td className="d-none d-md-table-cell">{props.item.hilineId || ''}</td>
            <td className="d-none d-md-table-cell">{props.item.description}</td>
            <td className="d-none d-md-table-cell">{props.item.vendor}</td>
            <td className="d-none d-md-table-cell">{props.item.orderNumber}</td>
            <td className="d-none d-md-table-cell">{props.item.wholesale}</td>
            <td className="text-right d-none d-md-table-cell">
                <button className="btn btn-secondary" onClick={() => props.onReceiveClick(props.item.id)}>Receive</button>
            </td>
            <td className="d-md-none">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">{props.item.description}</h4>
                        <p className="card-text">
                            UPC: {props.item.upc}<br />
                            {props.item.hilineId && `Hiline ID: ${props.item.hilineId}<br />`}
                            Vendor: {props.item.vendor}<br />
                            Order Number: {props.item.orderNumber}<br />
                        </p>
                        <button className="btn btn-secondary btn-block" onClick={() => props.onReceiveClick(props.item.id)}>Receive</button>
                    </div>
                </div>
            </td>
        </tr >
    );
}
ListRowComponent.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        upc: PropTypes.string.isRequired,
        hilineId: PropTypes.string,
        description: PropTypes.string.isRequired,
    }).isRequired,

    onReceiveClick: PropTypes.func.isRequired,
};
