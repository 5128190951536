import React from 'react';
import PropTypes from 'prop-types';
import formatDate from 'date-fns/format';

function computeJobStatus({
    completed,
    failed,
    queued,
    running,
    scheduled,
}) {
    if (completed) {
        return 'DONE';
    } else if (queued) {
        return 'PENDING';
    } else if (failed) {
        if (scheduled) {
            return 'FAILED, RETRYING';
        } else {
            return 'FAILED';
        }
    } else if (running) {
        return 'RUNNING';
    }
}


export default function QueueStatusComponent(props) {
    return (
        <table className="table table-striped">
            <thead>
                <th>HiLine ID</th>
                <th>Status</th>
                <th>When</th>
            </thead>
            <tbody>
                {(props.jobsArray || []).map((job) => (
                    <tr key={job.jobId}>
                        <td title={job.serialNumber || ''}>{job.hilineId || 'N/A'}</td>
                        <td>{computeJobStatus(job)}</td>
                        {(job.queued || job.scheduled) && <td>{formatDate(job.willRunAt, 'YYYY-MM-DD hh:mma')}</td>}
                        {job.completed && <td>{formatDate(job.lastFinishedAt, 'YYYY-MM-DD hh:mma')}</td>}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

QueueStatusComponent.propTypes = {
    jobsArray: PropTypes.arrayOf(PropTypes.shape({
        jobId: PropTypes.any.isRequired,
        hilineId: PropTypes.string,
        serialNumber: PropTypes.string,
        //isPending: PropTypes.bool.isRequired,
        //isFinished: PropTypes.bool.isRequired,
        willRunAt: PropTypes.string,
        lastFinishedAt: PropTypes.string,
        completed: PropTypes.bool,
        failed: PropTypes.bool,
        queued: PropTypes.bool,
        running: PropTypes.bool,
        scheduled: PropTypes.bool,
    })).isRequired,
};
