import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { call as restCall } from '../lib/api';
import { captureException } from '../sentry';

import printDuck from '../redux/ducks/print';

import Alert from 'react-s-alert';
import PrinterSelectionComponent from './printerSelectionComponent';


export class PrinterSelectionContainer extends React.Component {
    constructor(props = {}) {
        super(props);
        this.handlePrinterSelection = this.handlePrinterSelection.bind(this);
    }

    handlePrinterSelection(printerId) {
        this.props.setPrinter(printerId);
    }

    componentDidMount() {
        if (this.props.printerArray.length === 0) {
            restCall('/api/vendor/printers')
                .then((printers) => {
                    this.props.savePrinters(printers);

                    if (!this.props.selectedPrinter && printers[0]) {
                        this.handlePrinterSelection(printers[0].value);
                    }
                })
                .catch((err) => {
                    console.error(err); // eslint-disable-line
                    Alert.error(err.message || err.toString());
                    captureException(err, {
                        setContext: {
                            component: 'PrinterSelectionContainer',
                        },
                    });
                });
        }
    }

    render() {
        return (
            <div className="row justify-content-end">
                <div className="col-md-12 text-right">
                    {
                        this.props.selectedPrinter && this.props.printerArray && (
                            <small><PrinterSelectionComponent
                                onPrinterSelect={this.handlePrinterSelection}
                                printerOptions={this.props.printerArray}
                                selectedPrinter={this.props.selectedPrinter || ''}
                            /></small>
                        )
                    }
                </div>
            </div>
        );
    }
}

PrinterSelectionContainer.propTypes = {
    setPrinter: PropTypes.func.isRequired,
    savePrinters: PropTypes.func.isRequired,
    printerArray: PropTypes.array.isRequired,
    selectedPrinter: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        selectedPrinter: printDuck.selectors.getPrinterId(state.print) || null,
        printerArray: printDuck.selectors.getPrinters(state.print),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setPrinter: (printerId) => dispatch(printDuck.creators.setPrinterId(printerId)),
        savePrinters: (printers) => dispatch(printDuck.creators.savePrinters(printers)),
    };
};

const ContainerOutput = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PrinterSelectionContainer);

export default ContainerOutput;
