import { call as callApi } from './api';
import { CategoryOptionModel, ProductModel, BrandOptionModel } from '@butztech/hiline-product-details';

/**
 * Loads Category Options from BigCommerce
 * @returns {CategoryOptionModel[]}
 */
export function loadProductCategories() {
    return callApi('/api/vendor/bigcommerce/categories')
        .then((categoriesArray = []) => categoriesArray.map((c) => new CategoryOptionModel(c)));
}
/**
 * Loads Category Options from BigCommerce
 * @returns {CategoryOptionModel[]}
 */
export function loadBrandsCategories() {
    return callApi('/api/vendor/bigcommerce/brands')
        .then((brandsArray = []) => brandsArray.map((c) => new BrandOptionModel(c)));
}

/**
 * Loads Brands from BigCommerce
 * @returns {BrandOptionModel[]}
 */
export function loadProductBrands() {
    return callApi('/api/vendor/bigcommerce/brands')
        .then((brandsArray = []) => brandsArray.map((c) => new BrandOptionModel(c)));
}


/**
 * Creates a Product Model from Semantics3 Data
 * @param {any} productName 
 * @param {any} productUpc 
 * @param {Object} [productOptions={}]
 * @param {Number} [productOptions.uploadPrice]
 * @param {Number} [productOptions.uploadComparePrice]
 * @returns {ProductModel}
 */
export function loadProductModel(productName, productUpc, productOptions = {}, productId) {

    return callApi(`/api/vendor/semantics3/${productUpc}`)
        .then((semantics3Result) => {
            let semantics3Data = semantics3Result && semantics3Result.results && semantics3Result.results[0];
            let productModel = new ProductModel({
                id: productId,
                title: productName,
                upc: productUpc,
                body_html: ProductModel.buildSem3Description(semantics3Data || {}),
                price: productOptions.uploadPrice || null,
                compare_at_price: productOptions.uploadComparePrice || null,
                asin: productOptions.uploadASIN || null,
                likeNewPrice: productOptions.likeNewPrice || null,
                newPrice: productOptions.newPrice || null,
            });
            if (semantics3Data) {
                let length = ((semantics3Data.length || 0) * 0.0393701).toFixed(2);
                let width = ((semantics3Data.width || 0) * 0.0393701).toFixed(2);
                let height = ((semantics3Data.height || 0) * 0.0393701).toFixed(2);

                productModel = new ProductModel({
                    id: productId,
                    title: semantics3Data.name || productName,
                    upc: productUpc,
                    body_html: ProductModel.buildSem3Description(semantics3Data || {}),
                    weight: !semantics3Data.weight ? 0 : (semantics3Data.weight / 1000 * 0.035274).toFixed(2), // mg -> g -> oz
                    weightUnits: 'oz',
                    length,
                    width,
                    height,
                    images: (semantics3Data.images || []).map((src) => ({
                        src,
                        _include: false,
                    })),
                    product_type: '',
                    categories: [],
                    brandId: '',
                    price: productOptions.uploadPrice || null,
                    compare_at_price: productOptions.uploadComparePrice || null,
                    asin: productOptions.uploadASIN || null,
                    likeNewPrice: productOptions.likeNewPrice || null,
                    newPrice: productOptions.newPrice || null,
                });
            }

            return productModel;
        });
}

export function saveProductModel(productModel) {
    return callApi('/api/vendor/bigcommerce/product', 'POST', productModel)
        .then((productResult) => {
            return productResult && productResult.id;
        });
}

/**
 * Checks for the existance of a upc in BigCommerce
 * @param {String} upc 
 * @returns {Promise<Boolean>}
 */
export function checkProductExists(upc) {
    return callApi(`/api/vendor/bigcommerce/product/${upc}`)
        .then((results) => {
            return results && results.data && results.data.length > 0;
        });
}
