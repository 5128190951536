import { delay } from 'redux-saga';
import { call, takeEvery, select, put } from 'redux-saga/effects';
import authDuck from './ducks/auth';

const isDev = window.location.hostname === 'localhost';

var sessionCheckTimeout;
function* checkSession() {
    const state = yield select();

    let isAuth = authDuck.selectors.isAuthenticated(state.auth);

    if (sessionCheckTimeout == null && isAuth) {
        sessionCheckTimeout = true;
        yield delay(30 * 60 * 1000);
        let action = yield call(authDuck.creators.checkSession());
        yield put(action);
        sessionCheckTimeout = false;
    }
}

function* checkExpiredSession() {
    const state = yield select();
    let isAuth = authDuck.selectors.isAuthenticated(state.auth);
    if (!isAuth && authDuck.selectors.getAccessToken(state.auth)) {
        yield put(authDuck.creators.logout());
    }
}

function* heartbeatSetup() {
    yield put({ type: 'APP.HEARTBEAT' });
}

function* heartbeat() {
    yield delay(5 * 1000);
    yield put({ type: 'APP.HEARTBEAT' });
}

function* initHelpdeskWidget() {
    const state = yield select();
    let userInfo = authDuck.selectors.getCurrentUser(state.auth);

    if (userInfo && userInfo.canSeeHelpdeskIntegration && !document.getElementById('freshwidget-frame')) {
        let userEmail = userInfo.email || userInfo.username;
        
        window.FreshWidget.init('', {
            queryString: `&widgetType=popup&searchArea=no&&submitTitle=Send&helpdesk_ticket[requester]=${userEmail || ''}`,
            backgroundImage: 'https://s3.amazonaws.com/assets.freshdesk.com/widget/help-button.png',
            utf8: '✓',
            widgetType: 'popup',
            buttonType: 'text',
            buttonText: 'Get Help or Report Bug',
            buttonColor: 'white',
            buttonBg: '#006063',
            alignment: '3',
            offset: window.innerWidth ? `${window.innerWidth - 200}px` : '10px',
            formHeight: '500px',
            url: 'https://butztech.freshdesk.com',
            loadOnEvent: 'immediate',
        });
    }
}

function removeHelpdeskWidget() {
    if (window.FreshWidget) {
        window.FreshWidget.destroy();
    }
}

export default function* () {
    yield takeEvery('APP.INIT', checkSession);
    yield takeEvery('APP.INIT', heartbeatSetup);
    yield takeEvery(authDuck.types.SET, checkSession);
    yield takeEvery('*', checkExpiredSession);

    if (!isDev) {
        yield takeEvery('APP.HEARTBEAT', heartbeat);
    }

    yield takeEvery('APP.WINDOW.LOAD', initHelpdeskWidget);
    yield takeEvery(authDuck.types.SET_USER_DETAILS, initHelpdeskWidget);
    yield takeEvery(authDuck.types.CLEAR, removeHelpdeskWidget);
}
