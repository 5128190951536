import React, { Fragment } from 'react';
import { call as restCall } from '../../../lib/api';
import QueueStatusComponent from './QueueStatusComponent';

export default class QueueStatusContainer extends React.Component {
    constructor(props = {}) {
        super(props);
        this.state = { jobsArray: [] };

        this.handleRetrieveQueue = this.handleRetrieveQueue.bind(this);
    }
    handleRetrieveQueue() {
        restCall('/api/reporting/processingQueue')
            .then((jobsArray) => {
                this.setState({
                    jobsArray: jobsArray || [],
                });
            });
    }
    render() {
        return (
            <Fragment>
                {this.state.jobsArray.length > 0 && <QueueStatusComponent jobsArray={this.state.jobsArray} />}
                <button className="btn btn-secondary btn-sm" onClick={this.handleRetrieveQueue}>Load/Refresh QueueStatus</button>
            </Fragment>
        );
    }
}
