import React from 'react';
import { call as restCall } from '../../../lib/api';

import Alert from 'react-s-alert';
import ProductLookup from './ProductLookup';
import ProductEdit from './ProductEdit';

export default class SingleLabelPrintingContainer extends React.Component {
    constructor(props = {}) {
        super(props);
        this.state = {};
        this.handleLookup = this.handleLookup.bind(this);
        this.handleUpdateItem = this.handleUpdateItem.bind(this);
        this.handleUpdateCancel = this.handleUpdateCancel.bind(this);
    }
    handleLookup(hilineId) {
        return restCall(`/api/inventory?hilineId=${hilineId.trim()}`)
            .then((r) => {
                if (r.success) {
                    if (r.data.length === 1) {
                        if (r.data[0].gradedAt) {
                            Alert.error('That item has already been graded and cannot be corrected');
                            return;
                        }
                        this.setState({
                            item: {
                                hilineId: hilineId.trim(),
                                id: r.data[0].id,
                                upc: r.data[0].upc,
                                description: r.data[0].description,
                            },
                        });
                    } else if (r.data.length === 0) {
                        Alert.error('No item found with that Hiline ID');
                    } else {
                        Alert.error('More than one item found with that Hiline ID');
                    }
                } else {
                    Alert.error('An error occurred while searching for items');
                }
            });
    }
    handleUpdateItem(itemProps) {
        return restCall(`/api/inventory/correct/${this.state.item.id}`, 'POST', itemProps)
            .then((r) => {
                if (r.success) {
                    Alert.success('Item updated');
                    this.setState({ item: null });
                } else {
                    Alert.error('An error occurred while updating the item');
                }
            });
    }
    handleUpdateCancel(e) {
        e.preventDefault();
        this.setState({ item: null });
    }
    render() {
        return (
            <div>
                {!this.state.item && <ProductLookup onSubmit={this.handleLookup} />}

                {this.state.item && <ProductEdit
                    onSubmit={this.handleUpdateItem}
                    onCancel={this.handleUpdateCancel}
                    hilineId={this.state.item.hilineId}
                    upc={this.state.item.upc}
                    description={this.state.item.description}
                />}
            </div>
        );
    }
}
