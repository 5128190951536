import React from 'react';
import PropTypes from 'prop-types';
import { getChildCategories, getRootCategories } from '../../../lib/eBay';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GradeComponent from './gradeComponent';

import {
    calculateMinimumPrice,
    getProduct,
    gradeItem,
    loadConditions,
    loadLocations,
    validateGrade,
} from '../../../lib/grading';
import {
    checkItemExistsOnEbay,
    createListing,
    updateItemQuantity,
} from '../../../lib/eBay';

import ProductImport from '../..//ProductImport';
import { CategorySelectionModal } from '@butztech/hiline-product-details';
import _ from 'lodash';

export default class GradingContainer extends React.Component {
    constructor(props = {}) {
        super(props);
        this.state = {
            item: props.item,
            conditions: null,
            locations: null,
            loadingData: false,
            isItemGraded: false,
            showModal: false,
            infoLabels: [],
            warnLabels: [],
            productSummaries: null,
            leafCategory: null,
            rootCategoryStore: [],
            childCategoryStore: {},
        };

        this.handleGradeItem = this.handleGradeItem.bind(this);
        this.handleSerialNumberValidation = this.handleSerialNumberValidation.bind(
            this,
        );
        this.handleSelectedRoot = this.handleSelectedRoot.bind(this);
        this.handleSelectedCategory = this.handleSelectedCategory.bind(this);
        this._addNewInfoLabel = this._addNewInfoLabel.bind(this);
        this.handleSelectedProductSummary = this.handleSelectedProductSummary.bind(
            this,
        );
        this.selectedCustomBrand = this.selectedCustomBrand.bind(this);
        this.getProductImport = this.getProductImport.bind(this);
        this.selectCategoryAndCreateListing = this.selectCategoryAndCreateListing.bind(
            this,
        );
        this.loadRootCategories = this.loadRootCategories.bind(this);
        this.calculateMinimumPriceIfFBA = this.calculateMinimumPriceIfFBA.bind(
            this,
        );
    }

    _startLoading() {
        this.setState({
            loadingData: true,
        });
    }

    _tryFinishLoading() {
        this.setState((prevState) => {
            if (prevState.item && prevState.conditions && prevState.locations) {
                return {
                    loadingData: false,
                };
            }
            return {};
        });
    }

    async calculateMinimumPrice() {
        try {
            const productResp = await getProduct(this.state.item.upc);
            if (productResp) {
                /**
                 * Calculate minimum price using product weight and retails price and set to  minimum price in item
                 */
                const item = this.state.item;

                item.minimumPrice = calculateMinimumPrice(
                    productResp.weight,
                    productResp.retail_price,
                );
                // if (item.lastMinPrice) {
                //     item.minimumPrice = item.lastMinPrice;
                // }
                if (item.lastNonFbaMinPrice) {
                    item.minimumPrice = item.lastNonFbaMinPrice;
                }

                item.likeNewPrice = productResp.calculated_price;
                item.weight = productResp.weight;
                this.setState({
                    item: item,
                });
                this.forceUpdate();
            }
        } catch (error) {
            console.error('error', error); // eslint-disable-line no-console
        }
    }

    async UNSAFE_componentWillMount() {
        this._startLoading();
        if (this.state.item == null && this.props.match.params.itemId != null) {
            try {
                const itemResp = await this.props.onAttemptLoadItem(
                    this.props.match.params.itemId,
                );

                if (itemResp) {
                    this.setState({
                        item: itemResp,
                    });
                }
                this._tryFinishLoading();
            } catch (error) {
                console.error('error', error); // eslint-disable-line no-console
            }
        }

        /**
         * Get product details from the BigCommerce for count minimum price
         */
        if (this.state.item) {
            await this.calculateMinimumPrice();
        }

        if (this.state.conditions == null) {
            loadConditions().then((conditions) => {
                if (conditions) {
                    this.setState({
                        conditions,
                    });
                }
                this._tryFinishLoading();
            });
        }

        if (this.state.locations == null) {
            loadLocations().then((locations) => {
                if (locations) {
                    this.setState({
                        locations,
                    });
                }
                this._tryFinishLoading();
            });
        }
    }

    async componentDidMount() {
        if (this.state.rootCategoryStore.length === 0) {
            await this.loadRootCategories();
        }
    }

    async loadRootCategories() {
        const response = await getRootCategories();
        this.setState({
            rootCategoryStore: response,
        });
        return response;
    }

    _addNewInfoLabel(value) {
        const currentInfoLabels = this.state.infoLabels;
        currentInfoLabels.push(value);
        this.setState({ infoLabels: currentInfoLabels });
    }

    _addNewWarnLabel(value) {
        const currentWarnLabels = this.state.warnLabels;
        currentWarnLabels.push(value);
        this.setState({ warnLabels: currentWarnLabels });
    }

    async handleGradeItem(e) {
        this._addNewInfoLabel('Grade item is in progress');

        let fd = new FormData(e.target);
        const doNotSend = fd.has('doNotSend');
        const fba = fd.has('fba');
        let obj = {
            id: fd.get('itemId'),
            condition: fd.get('condition'),
            location: fd.get('location'),
            comments: fd.get('comments'),
            minimumPrice: fd.get('minimumPrice'),
            serialNumber: fd.has('noSerialNumber')
                ? null
                : fd.get('serialNumber'),
            hasSerialNumber: !fd.has('noSerialNumber'),
            minimumPriceEBay: 0,
            minimumPriceBigCommerce: 1,
            doNotSend,
            fba,
        };
        if (!validateGrade(obj)) {
            return false;
        }

        try {
            const response = await gradeItem(obj);
            // this.props.history.push('/grading');
            if (response) {
                this._addNewInfoLabel('Item has been graded successfully');
            } else {
                this._addNewInfoLabel('Error occurred while grading an item');
            }
            if (!doNotSend && response) {
                await this.createListingOnEbay(response);
            }
        } catch (error) {
            console.error(error); // eslint-disable-line no-console
        }
    }

    /**
     * This will execute when item is graded and after create listing on ebay
     */
    async createListingOnEbay(item) {
        try {
            this._addNewInfoLabel(
                'Check item exist by SKU or UPC for listing on ebay',
            );
            const response = await checkItemExistsOnEbay(item.id);
            if (!response) {
                return this._addNewInfoLabel(
                    'Error occurred while checking item on ebay',
                );
            }
            if (response.skuFound) {
                this._addNewInfoLabel('Found item by SKU');
                await this.updateItemQuantity(response, item);
            } else if (response.upcFound) {
                this._addNewInfoLabel('Found item by UPC');
                await this.createListing(response, item);
            } else if (response.variantMatchFound) {
                this._addNewInfoLabel('Item matched with product variant');
                await this.createListing(response, item);
            } else if (response.notFound) {
                this._addNewInfoLabel('Item is not found by SKU or UPC');
                await this.selectCategoryAndCreateListing(item);
            } else {
                this._addNewInfoLabel(
                    'Error occurred while checking item on ebay',
                );
            }
        } catch (error) {
            console.error(error); // eslint-disable-line no-console
        }
    }

    /**
     * This will execute when item is found by SKU
     */
    async updateItemQuantity(data, item) {
        let params = { ...data, gradedItem: item };
        const response = await updateItemQuantity(params);
        if (response) {
            this._addNewInfoLabel('Item successfully updated on ebay');
        } else {
            this._addNewInfoLabel('Error occurred while updating item on ebay');
        }
    }

    showWarnings(response) {
        if (response.warnings && Array.isArray(response.warnings)) {
            response.warnings.forEach((value) => {
                this._addNewWarnLabel(value.message);
            });
        }
    }

    /**
     * This will execute when Item is found by UPC
     */
    async createListing(data, gradedItem) {
        this._addNewInfoLabel('Creating listing on ebay in progress');
        if (data.productSummaries) {
            const response = await createListing({
                ..._.first(data.productSummaries),
                upcFound: true,
                gradedItem: gradedItem,
            });
            if (response) {
                this.showWarnings(response);
                this._addNewInfoLabel('Product listed on ebay successfully');
            } else {
                this._addNewInfoLabel('Error occurred while listing on ebay');
            }
        }
        if (data.variantMatchFound) {
            const response = await createListing({
                ...data,
                variantMatchFound: true,
                gradedItem: gradedItem,
            });
            if (response) {
                this.showWarnings(response);
                this._addNewInfoLabel('Product listed on ebay successfully');
            } else {
                this._addNewInfoLabel('Error occurred while listing on ebay');
            }
        }
    }

    /**
     * This will execute when item is not found by SKU or UPC
     */
    async selectCategoryAndCreateListing(item) {
        this._addNewInfoLabel('Loading categories in progress');
        try {
            let response = [];
            if (this.state.rootCategoryStore.length > 0) {
                response = this.state.rootCategoryStore;
            } else {
                response = await this.loadRootCategories();
            }
            if (!response) {
                return;
            }
            this.setState({
                showModal: true,
                rootCategories: response,
                gradedItem: item,
                productSummaries: null,
            });
        } catch (error) {
            console.error(error); // eslint-disable-line no-console
        }
    }

    handleSerialNumberValidation(obj) {
        if (obj.checkbox.checked) {
            obj.input.setCustomValidity('');

            return;
        }

        if (obj.input.value.trim().length > 0) {
            obj.input.setCustomValidity('');

            return;
        }

        obj.input.setCustomValidity('A serial number must be entered');
    }

    /**
     * Handles the selected root category from category modal
     */
    async handleSelectedRoot(category) {
        try {
            let response = [];
            if (this.state.childCategoryStore[category.value]) {
                response = this.state.childCategoryStore[category.value];
            } else {
                response = await getChildCategories(category.value);
                const childCategoryStore = this.state.childCategoryStore;
                childCategoryStore[category.value] = response;
                this.setState({
                    childCategoryStore: childCategoryStore,
                    productSummaries: null,
                });
            }
            if (!response) {
                return;
            }
            this.setState({
                childCategories: response,
                productSummaries: null,
            });
        } catch (error) {
            console.error(error); // eslint-disable-line no-console
        }
    }

    /**
     * Handles final selected category from modal and request for create a listing
     */
    async handleSelectedCategory(cateogry) {
        this._addNewInfoLabel('Category has been selected.');
        this.setState({
            productSummaries: [],
            leafCategory: _.last(cateogry),
        });

        /*const response = await searchBySelectedLeafCategory(_.last(cateogry));
        if (_.isEmpty(response)) {
            this.setState({
                productSummaries: [],
                leafCategory: _.last(cateogry),
            });
            return;
        }
        this.setState({
            productSummaries: response.productSummaries,
            leafCategory: _.last(cateogry),
        });*/
    }

    /**
     * Handles final selected product summary, create listing by selected product summary
     */
    async handleSelectedProductSummary(productSummary) {
        this.setState({
            showModal: false,
        });
        this._addNewInfoLabel(
            'Product Summary has been selected',
            productSummary,
        );
        let createListingResponse;
        this._addNewInfoLabel('Creating listing on ebay in progress');
        createListingResponse = await createListing({
            ...productSummary,
            upcFound: false,
            gradedItem: this.state.gradedItem,
            selectedLeafCategory: this.state.leafCategory,
        });
        if (createListingResponse) {
            this.showWarnings(createListingResponse);
            this._addNewInfoLabel('Product listed on ebay successfully');
        } else {
            this._addNewInfoLabel('Error occurred while listing on ebay');
        }
    }

    /**
     * Handles final selected custom brand, create listing by selected custom brand
     */
    async selectedCustomBrand(customBrand) {
        this.setState({
            showModal: false,
        });
        this._addNewInfoLabel('Product Summary has been selected');
        let createListingResponse;
        this._addNewInfoLabel('Creating listing on ebay in progress');
        createListingResponse = await createListing({
            customBrand: customBrand,
            upcFound: false,
            isCustomBrand: true,
            gradedItem: this.state.gradedItem,
            selectedLeafCategory: this.state.leafCategory,
        });
        if (createListingResponse) {
            this.showWarnings(createListingResponse);
            this._addNewInfoLabel('Product listed on ebay successfully');
        } else {
            this._addNewInfoLabel('Error occurred while listing on ebay');
        }
    }

    async getProductImport() {
        if (this.state.item) {
            await this.calculateMinimumPrice();
        }
    }

    calculateMinimumPriceIfFBA(isFBA) {
        let item = this.state.item;
        loadConditions().then((conditions) => {
            if (conditions) {
                if (isFBA) {
                    conditions = conditions.slice(0, 3);
                    item.minimumPrice = calculateMinimumPrice(
                        item.weight,
                        item.likeNewPrice,
                        true,
                    );

                    if (item.lastFbaMinPrice) {
                        item.minimumPrice = item.lastFbaMinPrice;
                    }
                } else {
                    item.minimumPrice = calculateMinimumPrice(
                        item.weight,
                        item.likeNewPrice,
                        false,
                    );
                    // if (item.lastMinPrice) {
                    //     item.minimumPrice = item.lastMinPrice;
                    // }
                    if (item.lastNonFbaMinPrice) {
                        item.minimumPrice = item.lastNonFbaMinPrice;
                    }
                }
                this.setState({ item, conditions });
                this.forceUpdate();
            }
        });
    }

    render() {
        return (
            <div>
                {this.state.loadingData && (
                    <div className="text-center">
                        <FontAwesomeIcon icon="spinner" size="4x" spin />
                        <br />
                        <span>Loading Data</span>
                    </div>
                )}
                {this.state.item &&
                    this.state.conditions &&
                    this.state.locations && (
                    <ProductImport
                        id={this.state.item.id}
                        upc={this.state.item.upc}
                        name={this.state.item.description}
                        uploadPrice={this.state.item.storePrice}
                        uploadComparePrice={this.state.item.retailPrice}
                        asin={this.state.item.asin}
                        getProductImport={this.getProductImport}
                        likeNewPrice={ Number(this.state.item.likeNewPrice) }
                        newPrice={ Number(this.state.item.newPrice) }
                    >
                        <GradeComponent
                            item={this.state.item}
                            conditions={this.state.conditions}
                            locations={this.state.locations}
                            onGradeSubmit={this.handleGradeItem}
                            onValidateSerialNumber={
                                this.handleSerialNumberValidation
                            }
                            onGraded={this.state.isItemGraded}
                            infoLabels={this.state.infoLabels}
                            warnLabels={this.state.warnLabels}
                            calculateMinimumPriceIfFBA={
                                this.calculateMinimumPriceIfFBA
                            }
                        />
                        {this.state.rootCategories && (
                            <CategorySelectionModal
                                rootCategories={this.state.rootCategories}
                                showModal={this.state.showModal}
                                selectedRootCategory={
                                    this.handleSelectedRoot
                                }
                                childCategories={this.state.childCategories}
                                selectedCategory={
                                    this.handleSelectedCategory
                                }
                                productSummaries={
                                    this.state.productSummaries
                                }
                                getSelectedProductSummary={
                                    this.handleSelectedProductSummary
                                }
                                getSelectedCustomBrand={
                                    this.selectedCustomBrand
                                }
                            />
                        )}
                    </ProductImport>
                )}
            </div>
        );
    }
}

GradingContainer.propTypes = {
    item: PropTypes.object,
    match: PropTypes.object.isRequired,
    onAttemptLoadItem: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};
