import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { checkProductExists} from '../../lib/productImport';
import Product from '../Product';

export default class ProductImport extends Component {
    constructor(props = {}) {
        super(props);

        this.state = {
            productExists: false,
            hasLoadedProduct: false,
        };

        this.handleProductSaveSuccess = this.handleProductSaveSuccess.bind(this);
        this._checkProductExists = this._checkProductExists.bind(this);
    }

    _checkProductExists() {
        return checkProductExists(this.props.upc)
            .then((productExists) => {
                this.setState((state) => ({
                    ...state,
                    productExists,
                    hasLoadedProduct: true,
                }));
            });
    }

    componentDidMount() {
        this._checkProductExists();
    }

    handleProductSaveSuccess() {
        this.props.getProductImport();
        this.setState((state) => ({
            ...state,
            productExists: true,
            hasLoadedProduct: true,
        }));
    }

    render() {
        if (this.state.productExists === false && this.state.hasLoadedProduct) {
            return <Product
                id={this.props.id}
                name={this.props.name}
                upc={this.props.upc}
                uploadPrice={this.props.uploadPrice}
                uploadComparePrice={this.props.uploadComparePrice}
                asin={this.props.asin}
                onProductSaveSuccess={this.handleProductSaveSuccess}
                likeNewPrice={this.props.likeNewPrice}
                newPrice={this.props.newPrice}
            />;
        }

        if (this.state.productExists === true && this.state.hasLoadedProduct) {
            return this.props.children;
        }

        return (
            <div className="text-center mt-2">
                <FontAwesomeIcon icon="spinner" size="4x" spin /><br />
                <span>Loading</span>
            </div>
        );
    }
}

ProductImport.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    upc: PropTypes.string.isRequired,
    uploadPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    uploadComparePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    asin: PropTypes.string,
    getProductImport: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    likeNewPrice: PropTypes.number,
    newPrice: PropTypes.number,

};

//ProductImport.defaultProps = {
//    upc: '841163066355',
//    name: 'test',
//    uploadPrice: 101,
//    uploadComparePrice: 201,
//};
