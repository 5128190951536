import React from 'react';
import PropTypes from 'prop-types';

export default class ConditionComponent extends React.Component {

    render() {
        return (
            <div className="form-check">
                <label className="custom-control custom-radio">
                    <input type="radio" className="custom-control-input"
                        name="condition"
                        value={this.props.label}
                        required />
                    <span className="custom-control-indicator"></span>
                    <span className="custom-control-description">{this.props.label} - {this.props.description}</span>
                </label>
            </div>
        );
    }
}

ConditionComponent.propTypes = {
    abbr: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onSelect: PropTypes.func,
};
