import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CriteriaComponent from './criteriaComponent';
import TableComponent from './tableComponent';

import { loadReport, buildReportUrl } from '../../lib/reporting';
import { callDownload } from '../../lib/api';

export default class ReportingContainer extends Component {
    constructor(props = {}) {
        super(props);

        this.state = {
            rows: null,
            loadingRows: false,
            error: false,
        };
        this.handleCriteriaSubmit = this.handleCriteriaSubmit.bind(this);
        this.handleExportClick = this.handleExportClick.bind(this);
    }

    handleCriteriaSubmit(opts) {
        this.setState({ loadingRows: true });
        loadReport(opts)
            .then((rows, success) => {
                this.setState({
                    rows,
                    loadingRows: false,
                    error: success === false,
                });
            });
    }

    handleExportClick(opts) {
        opts.output = 'xlsx';
        let url = buildReportUrl(opts);
        callDownload(url, 'report.xlsx');
    }

    render() {
        return (
            <div>
                <h2>Reporting &amp; Export</h2>
                <CriteriaComponent onFormSubmit={this.handleCriteriaSubmit} onExportClick={this.handleExportClick} />

                {this.state.loadingRows &&
                    <div className="text-center mt-2">
                        <FontAwesomeIcon icon="spinner" size="4x" spin /><br />
                        <span>Loading</span>
                    </div>
                }
                {this.state.rows && !this.state.loadingRows && !this.state.error && <TableComponent rows={this.state.rows} />}
                {this.state.error && <p>An error occurred loading the data</p>}
            </div>
        );
    }

}
