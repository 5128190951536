import React from 'react';
import Alert from 'react-s-alert';
import Filter from './filterComponent';
import List from './list/listComponent';
import { captureException } from '../../../sentry';
import { receiveItem, loadItems } from '../../../lib/receiving';

export default class ReceivingLookupContainer extends React.Component {
    constructor(params = {}) {
        super(params);

        this.state = {
            items: [],
            filter: {
                orderNumber: null,
                upc: null,
                description: null,
            },
        };

        this.handleFilterClick = this.handleFilterClick.bind(this);
        this.handleHiLineNumberReceiveClick = this.handleHiLineNumberReceiveClick.bind(this);
        this.handleReceiveClick = this.handleReceiveClick.bind(this);
    }

    static _removeReceivedItems(items) {
        return items.filter((item) => item.receivedAt == null && item.receivedBy == null);
    }

    static _filterItems(items, filter = {}) {
        if (Object.keys(filter).length === 0 || Object.values(filter).every((v) => v == null)) {
            return []; //items;
        }

        return items.filter((item) =>
            Object.keys(filter).every((k) =>
                !filter[k]
                || (item[k].toLowerCase().indexOf(filter[k].toLowerCase()) > -1),
            ));
    }

    _updateItemState(item) {
        this.setState((prevState) => {
            let items = Array.from(prevState.items);
            let existingItem = items.find((i) => i.id === item.id);
            if (existingItem) {
                let itemLoc = items.indexOf(existingItem);
                items[itemLoc] = Object.assign(existingItem, item);
            } else {
                items.push(item);
            }

            return {
                items: ReceivingLookupContainer._removeReceivedItems(items),
            };
        });
    }

    _receiveItem(props) {
        return receiveItem(props)
            .then((r) => {
                if (r) {
                    this._updateItemState(r);
                }
            })
            .catch((err) => {
                console.error(err); // eslint-disable-line
                Alert.error('An unspecified error occured');

                captureException(err, {
                    setContext: {
                        props,
                        component: 'ReceivingLookupContainer._receiveItem',
                    },
                });
            });
    }

    handleFilterClick(orderNumber, upc, description) {
        this.setState({
            filter: {
                orderNumber: orderNumber || null,
                upc: upc || null,
                description: description || null,
            },
        });
    }

    handleHiLineNumberReceiveClick(hilineId) {
        this._receiveItem({
            hilineId,
        });
    }

    handleReceiveClick(itemId) {
        this._receiveItem({
            id: itemId,
        });
    }

    componentDidMount() {
        loadItems()
            .then((r) => {
                if (r) {
                    this.setState({ items: r });
                }
            })
            .catch((err) => {
                console.error(err); // eslint-disable-line
                Alert.error('An unspecified error occured');
                captureException(err, {
                    setContext: {
                        component: 'ReceivingLookupContainer.componentDidMount',
                    },
                });
            });
    }

    render() {
        let alertElement = null;
        let filteredItems = ReceivingLookupContainer._filterItems(this.state.items, this.state.filter);
        if (this.state.items.length === 0) {
            alertElement = (
                <div className="alert alert-secondary" role="alert">
                    No items need receiving
                </div>
            );
        }
        else if (filteredItems.length === 0) {
            alertElement = (
                <div className="alert alert-primary" role="alert">
                    Enter an Order Number, UPC, or Description to display matching items
                </div>
            );
        }

        return (
            <div>
                <Filter onHilineReceive={this.handleHiLineNumberReceiveClick} onFilterClick={this.handleFilterClick} />
                {alertElement || <List items={filteredItems} onReceiveClick={this.handleReceiveClick} />}
            </div>
        );
    }
}
